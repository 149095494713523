// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-static-about-js": () => import("./../../../src/templates/static/about.js" /* webpackChunkName: "component---src-templates-static-about-js" */),
  "component---src-templates-static-careers-js": () => import("./../../../src/templates/static/careers.js" /* webpackChunkName: "component---src-templates-static-careers-js" */),
  "component---src-templates-static-contact-us-js": () => import("./../../../src/templates/static/contact-us.js" /* webpackChunkName: "component---src-templates-static-contact-us-js" */),
  "component---src-templates-static-customer-service-js": () => import("./../../../src/templates/static/customer-service.js" /* webpackChunkName: "component---src-templates-static-customer-service-js" */),
  "component---src-templates-static-device-protection-js": () => import("./../../../src/templates/static/device-protection.js" /* webpackChunkName: "component---src-templates-static-device-protection-js" */),
  "component---src-templates-static-dtac-js": () => import("./../../../src/templates/static/dtac.js" /* webpackChunkName: "component---src-templates-static-dtac-js" */),
  "component---src-templates-static-i-surance-js": () => import("./../../../src/templates/static/i-surance.js" /* webpackChunkName: "component---src-templates-static-i-surance-js" */),
  "component---src-templates-static-index-js": () => import("./../../../src/templates/static/index.js" /* webpackChunkName: "component---src-templates-static-index-js" */),
  "component---src-templates-static-insurance-js": () => import("./../../../src/templates/static/insurance.js" /* webpackChunkName: "component---src-templates-static-insurance-js" */),
  "component---src-templates-static-jkopay-js": () => import("./../../../src/templates/static/jkopay.js" /* webpackChunkName: "component---src-templates-static-jkopay-js" */),
  "component---src-templates-static-news-insights-js": () => import("./../../../src/templates/static/news-insights.js" /* webpackChunkName: "component---src-templates-static-news-insights-js" */),
  "component---src-templates-static-not-found-js": () => import("./../../../src/templates/static/not-found.js" /* webpackChunkName: "component---src-templates-static-not-found-js" */),
  "component---src-templates-static-our-leaders-js": () => import("./../../../src/templates/static/our-leaders.js" /* webpackChunkName: "component---src-templates-static-our-leaders-js" */),
  "component---src-templates-static-partners-js": () => import("./../../../src/templates/static/partners.js" /* webpackChunkName: "component---src-templates-static-partners-js" */),
  "component---src-templates-static-platform-insurance-js": () => import("./../../../src/templates/static/platform-insurance.js" /* webpackChunkName: "component---src-templates-static-platform-insurance-js" */),
  "component---src-templates-static-platform-overview-js": () => import("./../../../src/templates/static/platform-overview.js" /* webpackChunkName: "component---src-templates-static-platform-overview-js" */),
  "component---src-templates-static-platform-technology-js": () => import("./../../../src/templates/static/platform-technology.js" /* webpackChunkName: "component---src-templates-static-platform-technology-js" */),
  "component---src-templates-static-rabbit-line-pay-js": () => import("./../../../src/templates/static/rabbit-line-pay.js" /* webpackChunkName: "component---src-templates-static-rabbit-line-pay-js" */),
  "component---src-templates-static-regional-leadership-js": () => import("./../../../src/templates/static/regional-leadership.js" /* webpackChunkName: "component---src-templates-static-regional-leadership-js" */),
  "component---src-templates-static-request-demo-js": () => import("./../../../src/templates/static/request-demo.js" /* webpackChunkName: "component---src-templates-static-request-demo-js" */),
  "component---src-templates-static-second-life-js": () => import("./../../../src/templates/static/second-life.js" /* webpackChunkName: "component---src-templates-static-second-life-js" */),
  "component---src-templates-static-sitemap-js": () => import("./../../../src/templates/static/sitemap.js" /* webpackChunkName: "component---src-templates-static-sitemap-js" */)
}

